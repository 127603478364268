@import '../../Styles/Typography';

.signalFormContainer {
  margin-top: 2.4rem;
  width: 56rem;
  padding-right: 21.4rem;
  .title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.8px;
    color: #616a73;
  }
}

.selectionContainer {
  display: flex;
  margin-top: 1rem;
}
.configurationWrapper {
  margin-top: 2.4rem;
}

.signalFieldInputContainer {
  height: 36px;
  width: 272px;
  margin-right: 1.2rem;
  display: flex;
  border: solid 1px #a2b5c7;
  align-items: center;
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border: solid 1px #097886;
  }
  span {
    max-width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name {
    @include body-secondary;
    line-height: 2;
    font-weight: 500;
    padding: 1rem;
    width: 100%;
  }
}
[data-connectedinput='true'] {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
[data-connectedinput='false'] {
  border-radius: 3px;
}
[data-inputerror='true'] {
  border-color: #e72c31;
}

.selectedOptionWrapper {
  display: flex;
}

.verticalDottedLines {
  display: flex;
  flex-direction: column;
}

.dottedLine {
  margin-left: 1.2rem;
  width: 27px;
  height: 34px;
  background:
    linear-gradient(to right, #a2b5c7 33%, #fff 0%) bottom/6px 2px repeat-x,
    /* bottom */ linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px repeat-y; /* left */
}
.dottedLineOne {
  margin-left: 1.2rem;
  width: 27px;
  height: 31px;
  background:
    linear-gradient(to right, #a2b5c7 33%, #fff 0%) bottom/6px 2px repeat-x,
    /* bottom */ linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px repeat-y; /* left */
}
.altKeyContainer:nth-of-type(2) {
  margin-top: 1.2rem;
}

.altKeyContainer {
  display: flex;
  .altKeyFormWrapper {
    margin-left: 0.8rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .select {
      width: 34.6rem;
    }
    .title {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      line-height: 14px;
      letter-spacing: 0.8px;
      color: #616a73;
      margin-bottom: 1rem;
    }
    .orText {
      margin-top: 1.2rem;
      margin-bottom: 0.2rem;
      line-height: 24px;
    }
    .configureBtn {
      width: 20.9rem;
      padding: 10px 0;
    }
    .configureNewBtn {
      width: 24.9rem;
      padding: 0;
    }
    .stepText {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2.4;
      color: #3d444a;
    }
    .singleDottedLine {
      margin-left: 1.2rem;
      width: 24px;
      height: 24px;
      background: linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px repeat-y; /* left */
    }
    .warningText {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2;
      color: #616a73;
      max-width: 34.7rem;
      margin-top: 0.4rem;
    }
    .errorText {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2;
      color: #e72c31;
      max-width: 34.7rem;
      margin-top: 0.4rem;
    }
  }
}

// Existing Alt Key Select
.existingAltKeyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1.2rem;

  .displayName {
    margin-right: auto;
  }
  .secondaryValue {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2;
    color: #616a73;
  }
}

// Signal Type Select
.signalTypeSelect {
  width: 10.7rem;

  div:first-child {
    height: 14px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
}
.option {
  height: 5rem;
  padding: 2rem 1rem;
  font-size: 1.2rem;
  color: #3d444a;
  line-height: 2;
  &:hover {
    background-color: #cfe9ec;
  }
  // Hover
  &[data-focus='true'] {
    background-color: #cfe9ec !important;
  }
  // Selected
  &[aria-selected='true'] {
    background-color: rgba(16, 145, 161, 0.1) !important;
  }
}

.inputRoot {
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #3d444a !important;
}
.endAdornment {
  top: 9px !important;
}
