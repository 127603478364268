@import '../Styles/Typography';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  .wrapper {
    background-color: rgba(255, 255, 255, 0.5);
    border: solid 1px white;
    border-radius: 50%;
    height: auto;
    padding-top: 100vw;
    width: 100%;
    left: -20vw;
    top: -14vw;
    position: relative;
  }
  .contentContainer {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    .closeButton {
      left: 5.2rem;
      top: 5.2rem;
      position: absolute;
      z-index: inherit;
    }
    .backButton {
      left: 8.2rem;
      top: 11.8rem;
      position: absolute;
      z-index: inherit;
      &:hover {
        .toolTip {
          visibility: visible;
        }
      }
    }
    .connectionWrapper {
      padding-top: 10.8rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .header {
        display: flex;
        align-items: center;
        width: 39.4rem;
        justify-content: space-between;
        margin-left: 13.4rem;
        margin-bottom: 4.8rem;

        .information {
          display: flex;
          flex-direction: column;
          padding: 5rem 0;

          .connectionName {
            @include header-secondary;
            white-space: nowrap;
            margin-bottom: 1rem;
          }
          .connectionTotalEvents {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #979fa7;
            margin-bottom: 3rem;
          }
          .connectionType {
            width: 169px;
            height: 36px;
            background-color: #d2d7de;
            border-radius: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
              font-size: 12px;
              font-weight: bold;
              line-height: 2.67;
              letter-spacing: 1px;
              text-align: center;
              color: #616a73;
            }
          }
        }
      }
      .instructions {
        @include body-primary;
        margin-bottom: 1.2rem;
        margin-left: 13.4rem;
      }
      .eventKeyList {
        overflow: auto;
        padding-left: 11.4rem;
        padding-bottom: 1rem;
        padding-right: 2rem;
        width: max-content;
        .event {
          width: 394px;
          height: 62px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          background-color: white;
          border-radius: 4px;
          margin-top: 1.2rem;
          margin-bottom: 1.2rem;
          margin-left: 1rem;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-left: 1.2rem;
          align-items: flex-start;
          & > span {
            // overrides MuiButton-label's justification
            justify-content: left;
          }
          .details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            .title {
              @include title-secondary;
              margin-bottom: 0.5rem;
              font-weight: 600;
            }
            .count {
              font-size: 10px;
              font-weight: bold;
              line-height: 1.4;
              letter-spacing: 0.8px;
              color: #979fa7;
            }
          }
        }
        .event:hover {
          box-shadow: 0 20px 25px 0 rgba(70, 71, 72, 0.1);
        }
      }
    }
  }
  .editButton {
    margin-right: 4.8rem;
    margin-left: auto;
    margin-top: 4.8rem;
  }
}

.signalContainer {
  width: 418px;
  max-height: 540px;
  box-shadow: 0 8px 20px 0 rgba(70, 71, 72, 0.2);
  background-color: white;
  .expandedHeader {
    display: flex;
    padding: 2.4rem;
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      .title {
        @include title-secondary;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
      .count {
        font-size: 10px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.8px;
        color: #979fa7;
        margin-bottom: 2.4rem;
      }
    }
    .expandedCloseButton {
      margin-left: auto;
      margin-top: -1.2rem;
      align-self: flex-start;
    }
  }
  .divider {
    margin: auto;
    width: 370px;
    height: 12px;
    opacity: 0.2;
    background-image: radial-gradient(
      circle at 50% 0,
      #6a7888,
      rgba(106, 120, 136, 0)
    );
  }
  .eventFieldList {
    max-height: 38rem;
    padding: 1.2rem 2.4rem 0rem 2.4rem;
    overflow-y: auto;
    .eventField {
      height: 3.8rem;
      display: flex;
      .fieldType {
        @include title-secondary;
        width: 6rem;
      }
      .fieldName {
        @include body-secondary;
      }
    }
  }
}

.toolTip {
  height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 15px 35px 0 rgba(70, 71, 72, 0.15);
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  visibility: hidden;
  top: 6rem;
  left: -1.3rem;
  background-color: #1091a1;
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #1091a1;
    border-width: 0.5rem;
    margin-left: -0.5rem;
  }
  &:before {
    border-width: 0.5rem;
    margin-left: -0.5rem;
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #1091a1;
  }
}

.toolTipText {
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 1rem 2rem;
  position: relative;
}
