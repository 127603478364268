@import '../Styles/ColorVariables';
@import '../Styles/Typography';

.footerButtons {
  button:first-child {
    margin-right: 20px;
  }
}

.datasetLookup {
  .spiel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    .backToDataSets {
      cursor: pointer;
      display: inline-block;

      span {
        position: relative;
        top: -8px;
        left: 2px;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #097886;
      }
    }
    .dataSetLabel {
      margin-top: 6px;

      span {
        position: relative;
        left: 6px;
        top: -8px;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .contentBox {
    width: 528px;
    overflow: hidden;
  }

  .slider {
    // Each "panel" in the slider is 528px wide, with a 10px spacers between
    // after the first and second panels. So, 528x3 + 20 => 1604.
    width: 1604px;
    display: flex;

    &.animateToFirstPanel {
      position: relative;
      transition: 500ms ease;
      transform: translateX(0px);
    }

    &.animateToSecondPanel {
      position: relative;
      transition: 500ms ease;
      transform: translateX(-548px);
    }

    &.animateToThirdPanel {
      position: relative;
      transition: 500ms ease;
      transform: translateX(-1096px);
    }

    .panel {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 528px;
      max-width: 528px;
      margin-right: 20px;

      &.two {
        left: 548px;
        position: relative;
      }

      &.three {
        margin-right: 0px;
        left: 1096px;
        position: relative;
      }
    }

    .noKeysMessage {
      margin-top: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #979fa7;
      padding: 0 40px;
    }

    .datasetTable {
      width: 100%;
      user-select: none;
      max-height: 385px;
      overflow-y: auto;

      .row {
        display: flex;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0);

        cursor: pointer;
        margin: 7px 0;
        height: 68px;
        padding: 4px;

        &:hover {
          background: rgba(16, 145, 161, 0.1);
          border: 1px solid #1091a1;
        }

        .icon {
          width: 44px;
          display: flex;
          padding-top: 10px;
          justify-content: center;

          .textIcon {
            position: relative;
            top: 4px;
            font-size: 10px;
            font-weight: 100;
          }
          &.metricValue {
            margin-top: 2px;
          }
          &.radioValue {
            margin-top: -10px;
          }
        }

        .metricFieldColumn {
          width: 20px;
          display: flex;
          margin-top: 11px;
        }

        .name {
          flex: 1;

          .title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            padding-top: 9px;
            color: #3d444a;

            &.dataSetField {
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 1px;
              text-transform: uppercase;
              margin-top: 4px;
              margin-bottom: 6px;
            }
          }

          .metakey {
            position: relative;
            left: -2px;

            span {
              position: relative;
              left: 3px;
              top: -6px;
              display: inline-block;
              color: #616a73;

              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
            }

            &.dataSetFieldTag {
              span {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                text-transform: none;
                left: 2px;
              }
            }

            &.metricValue {
              span {
                margin-right: 6px;
              }
              svg {
                position: relative;
                top: -1px;
              }
            }
          }
        }

        .fieldCount {
          width: 120px;
          color: #616a73;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .rightArrow {
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
