// Brand Colors
$burntred: #e72c31;
$seafoam-blue: #6aced3;
$dark-blue-grey: #181e45;

// UI Primary
$primary-dark: #979fa7;
$primary: #a2b5c7;
$primary-light: #edf1f7;
$accent: #1091a1;
$accent-hover: #0e7c8a;
$accent-active: #0b6874;
$accent-light: #cfe9ec;

// Actions

$success: #22c28b;
$error: #e72c31;
$warning: #e8d633;

// Typography

$text-dark: #3d444a;
$text-light: #616a73;
$text-info: #5f6a74;

// GrayScale

$gray800: #3d444a;
$gray600: #616a73;
$gray400: #616a73;
$gray200: #e4e7ea;
$gray000: #ffffff;
