$break-small: 1023px;

.stepContainer {
  display: flex;
  padding: 0rem 10.8rem 3.5rem 10.8rem;
  flex-shrink: 0;
  background: white;
  transition: all 250ms ease-in-out;
  .stepButtonContainer {
    display: flex;
    width: 100%;
    .stepField {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      align-items: center;
      .fieldIcon {
        width: 8.4rem;
        height: 3.6rem;
        border-radius: 3px;
        border: solid 1px #097886;
        margin-bottom: 1rem;
        &:not(:hover) {
          background-color: white;
        }
      }
      .fieldTitle {
        font-size: 10px;
        font-weight: bold;
        line-height: 1.4;
        color: #097886;
        letter-spacing: 0.8px;
        text-align: center;
        text-transform: uppercase;
      }
      .fieldChip {
        height: 16px;
        font-size: 1.1rem;
        top: auto;
        margin-left: 0.5rem;
      }
      &[data-active='false'] {
        .fieldIcon {
          border: solid 1px #6a7888;
          &:hover {
            background-color: #edf1f7;
          }
          &:disabled {
            border: solid 1px transparent;
            background-color: #edf1f7;
            svg {
              fill: #edf1f7;
            }
          }
        }
        .fieldTitle {
          color: #979fa7;
        }
        .fieldChip {
          background-color: #3d444a;
        }
      }
    }
  }
  .stepRule {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    .fieldIcon {
      width: 8.4rem;
      height: 3.6rem;
      border-radius: 3px;
      border: solid 1px #edf1f7;
      background-color: white;
      margin-bottom: 1rem;
    }
    .fieldTitle {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.4;
      color: #979fa7;
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
    }
    .fieldChip {
      width: 27px;
      height: 16px;
      font-size: 1.1rem;
      top: auto;
      margin-left: 0.5rem;
      background-color: #3d444a;
    }
  }
  .stepLineLeft {
    position: sticky;
    margin-top: 1.3rem;
    height: 6px;
    width: 70vw;
    background: #edf1f7;
  }
  .stepLine {
    position: sticky;
    margin-top: 1.3rem;
    height: 6px;
    width: 70vw;
    background-image: linear-gradient(to left, white, #edf1f7);
  }
  @media only screen and (max-width: $break-small) {
    .stepLine {
      display: none;
    }
  }
}

.signalSaveButton {
  display: flex;
  align-items: center;
  margin-left: 1rem !important;
  justify-content: center;
  .saveButton {
    width: 16rem;
  }
  .switchText {
    width: 30px;
    height: 14px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #979fa7;
    text-transform: uppercase;
  }
}

.expressionBuilderContainer {
  margin-bottom: 3.5rem;
  padding: 0rem 10.8rem;
  flex-grow: 1;
  padding-bottom: 15rem;
}
