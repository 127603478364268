.container {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  height: 100%;
  .closeButton {
    margin-left: 5.2rem;
    margin-top: 5.2rem;
    position: relative;
  }
  .bodyWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 10.8rem 0rem 0rem 6.2rem;
    height: 100%;
    overflow: auto;
    .signalTitle {
      height: 48px;
      font-size: 2.8rem;
      line-height: 1.29;
      color: #3d444a;
    }
    .signalInfo {
      max-width: 41rem;
      margin-bottom: 3.6rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #3d444a;
    }
    .eventList {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      padding-bottom: 2rem;
    }
    .connectionContainer {
      display: flex;
      padding: 1rem;
      .connectionInfo {
        display: flex;
        flex-direction: column;
        margin-right: 3rem;
        justify-content: center;
        align-items: flex-start;

        .connectionName {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.33;
          color: #3d444a;
          text-overflow: ellipsis;
          overflow-x: hidden;
          width: 20rem;
          text-align: left;
        }

        .connectionCount {
          font-size: 10px;
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: 0.8px;
          color: #979fa7;
        }
      }
      .connectionIcon {
        width: 60px;
        height: 60px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .connectionIconImage {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
}
.listItem {
  margin: 20px 0;
  position: relative;
}
