@import '../Styles/ColorVariables';
@import '../Styles/Typography';
$break-small: 1023px;
.stepInstructions {
  @include body-primary;
  margin-bottom: 3rem;
  padding: 0rem 10.8rem;
  font-size: 12px;
  color: #616a73;
  flex-shrink: 0;
}
.breakLine {
  height: 2px;
  border: 0;
  background: #f7f8fb;
  border-radius: 3px;
  margin-bottom: 2.4rem;
}
.errorText {
  color: #e72c31;
  font-size: 1rem;
  font-weight: 500;
}
.eventFieldContainer {
  display: flex;
  align-items: center;
  padding: 1.2rem 10.8rem;

  .fieldSelect {
    width: 140px;
    height: 12px;
    border-right: 0;
    border-width: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .includedError {
    width: 140px;
    border-right: 0;
    border-width: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #e72c31;
    margin-top: -1.5rem;
  }

  .fieldNameContainerError {
    height: 38.25px;
    width: 272px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    border: solid 2px #e72c31;
    align-items: center;

    span {
      max-width: 265px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      @include body-secondary;
      line-height: 1.5;
      padding: 1rem;
      width: 100%;
    }
    .editButton {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fieldNameContainer {
    height: 38.25px;
    width: 272px;
    margin-right: 1.2rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    border: solid 2px #a2b5c7;
    align-items: center;

    span {
      max-width: 265px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      @include body-secondary;
      line-height: 1.5;
      padding: 1rem;
      width: 100%;
    }
    .editButton {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fieldNameContainerDisabled {
    height: 36px;
    width: 272px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-left: -0.2rem;
    display: flex;
    border: solid 1px #e4e7ea;

    .name {
      @include body-secondary;
      line-height: 1.5;
      padding: 1rem;
    }
  }
  .fieldSelectDisabled {
    width: 140px;
    border-right: 0;
    border-color: #e4e7ea;
  }
  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    background: linear-gradient(
      to right,
      #fff 0%,
      rgba(207, 233, 236, 0.3) 5%,
      rgba(207, 233, 236, 0.3) 95%,
      #fff 100%
    );
    .fieldSelect {
      border-width: 2px;
      border-right: 0;
      border-color: $accent;
    }
    .includedError {
      border-width: 2px;
      border-right: 0;
      border-color: #e72c31;
    }
    .fieldSelectDisabled {
      border-color: #e4e7ea;
    }
    .fieldNameContainerDisabled {
      border-color: #e4e7ea;
    }
    .fieldNameContainer {
      border-color: $accent;
    }
    .includeButtonWrapper {
      visibility: visible;
    }
    .metaKeyButton {
      visibility: visible;
    }
    .metaKeyButtonExcluded {
      visibility: visible;
      margin-left: 15px;
    }
  }
}
.listEventFields {
  flex-grow: 1;
  margin-bottom: 1rem;
}
@media only screen and (max-width: $break-small) {
  .listEventFields {
    overflow: visible;
    margin-bottom: 5rem;
  }
}
.includeButtonWrapper {
  margin-left: auto;
  align-self: center;
  visibility: hidden;
  display: flex;
  align-items: center;
  .includeButton {
    font-size: 12px;
    color: #1091a1;
    line-height: 1.5;
    padding: 0;
    text-transform: capitalize;
    font-weight: normal;
    &:hover {
      background-color: transparent;
    }
  }
  .divider {
    margin: 0rem 1.2rem;
    width: 2px;
    height: 24px;
    border-radius: 1px;
    background-color: #a2b5c7;
  }
}

.time {
  .timeTitle {
    color: #616a73;
    font-size: 1.2rem;
    line-height: 3;
    padding-left: 16px;
    padding-right: 16px;
  }
  .timeOptions {
    display: flex;
  }

  &:hover {
    .timeTitle {
      color: #fff;
      background-color: #097886;
    }
  }
}

.metaKeyButton {
  visibility: hidden;
}
.metaKeyButtonExcluded {
  visibility: hidden;
  margin-left: 15px;
}
.keyConfigContainer {
  display: flex;
  position: relative;
  top: -1.2rem;
  left: 1.2rem;
  padding: 0 10.8rem;
  .dottedLine {
    margin-left: 1.2rem;
    width: 30px;
    height: 34px;
    background:
      linear-gradient(to right, #a2b5c7 33%, #fff 0%) bottom/6px 2px repeat-x,
      /* bottom */ linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px repeat-y; /* left */
  }
  .keyContainer {
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;
    margin-left: 0.8rem;
    width: 36.2rem;
    height: 3.8rem;
    border-radius: 3px;
    background-color: #edf1f7;
    .text {
      margin-left: 0.6rem;
      margin-right: auto;
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: #616a73;
    }
  }
  .keyConfigWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;
  }
  .singleDottedLine {
    margin-left: 1.2rem;
    margin-top: 0.8rem;
    width: 24px;
    height: 24px;
    background: linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px repeat-y; /* left */
  }
}
