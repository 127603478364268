.errorTitle {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.8rem;
  }
}

.content {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #5f6a74;
}

.errorButtonContainer {
  button:nth-child(2) {
    margin-left: 1.6rem;
  }
}
