.ql-mention-loading {
  line-height: 2rem;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list > li::before {
  display: none;
}

.ql-mention-list-item {
  cursor: pointer;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
  margin-top: 0 !important;
  text-align: left;
  line-height: 2rem;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  /* background-color: #d3e1eb; */
  text-decoration: none;
  background-color: #edf1f7 !important;
  border-radius: 4px;
}

.ql-mention-list-heading {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  color: #616f7c;
  line-height: 2rem;
  padding: 0.6rem 1.6rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.ql-mention-denotation-char-at {
  vertical-align: text-bottom;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 4px;
  background: transparent;
  padding: 3px 0;
  margin-right: 0;
  user-select: all;
  cursor: text !important;
}

.mention > span {
  margin: 0;
}

.orgImageIcon {
  border-color: #3d444a !important;
  background-color: white;
}

[data-mention-user] {
  background: transparent;
  color: #041e2b;
  padding: 3px;
  padding-right: 0;
  margin-left: 0;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.8rem;
}
.ql-mention-list-item[data-mention-user] {
  background: inherit;
}
