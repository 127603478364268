em {
  font-synthesis-style: auto;
}

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  padding: 0;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  height: 20px;
  width: 20px;
}

.ql-toolbar.ql-snow {
  padding: 6px 12px;
}

.ql-snow.ql-toolbar .icons button,
.ql-snow .ql-toolbar .icons button {
  height: 40px;
  width: 40px;
  display: flex;
}
.ql-snow.ql-toolbar .icons button:hover,
.ql-snow .ql-toolbar .icons button:hover,
.ql-snow.ql-toolbar .icons button.ql-active,
.ql-snow .ql-toolbar .icons button.ql-active {
  background: #edf1f7;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-stroke {
  stroke: #3c4349;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-fill {
  fill: #3c4349;
}

.ql-container {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.8rem;
}

.ql-blank {
  font-weight: 400;
}

.ql-editor.ql-blank::before {
  left: unset;
  right: unset;
  height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 88%;
  overflow: hidden;
}

.ql-mention-list-container {
  width: 320px !important;
  max-height: 320px;
  padding: 0;
  box-shadow: 0px 8px 20px rgba(70, 71, 72, 0.2);
  border-radius: 3px;
  /* overflow: hidden; */
  overflow-y: auto;
}

.ql-mention-list-item {
  /* padding: 0px !important; */
  padding: 10px 20px;
  margin-top: 8px;
  text-align: left;
  line-height: 2rem !important;
}
.ql-mention-list-item.selected {
  background-color: #edf1f7 !important;
  border-radius: 4px;
}

.ql-hash-tag-list-container {
  width: 320px !important;
  background-color: #fff;
  max-height: 320px;
  padding: 0;
  box-shadow: 0px 8px 20px rgba(70, 71, 72, 0.2);
  border-radius: 3px;
  /* overflow: hidden; */
  overflow-y: auto;
  z-index: 1;
}

.ql-hash-tag-list-item {
  /* padding: 0px !important; */
  padding: 10px 20px;
  margin-top: 8px;
  text-align: left;
  line-height: 2rem !important;
}
.ql-hash-tag-list-item.selected {
  background-color: #edf1f7 !important;
  border-radius: 4px;
}

/* Overrides for font sizing dropdown */
.ql-size-small {
  font-size: 1.6rem !important;
  line-height: 2.4rem !important;
  letter-spacing: -0.1px !important;
}
.ql-size-large {
  font-size: 2rem !important;
  line-height: 2.8rem !important;
  letter-spacing: -0.1px !important;
}
.ql-size-huge {
  font-size: 4.4rem !important;
  line-height: 5.4rem !important;
  letter-spacing: -0.5px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: 'Body' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: 'Sub Heading' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: 'Heading' !important;
}
.ql-snow .ql-picker.ql-size {
  width: 115px !important;
}

.focused {
  outline: 2px solid #041e2b !important;
  border-radius: 8px;
}

.ql-wavy {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: orange;
}
