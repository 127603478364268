@import '../Styles/ColorVariables';
@import '../Styles/Typography';
$break-large: 1023px;
$break-small: 1023px;

.headerInformation {
  display: flex;
  align-items: center;
  margin-top: -5rem;
  width: 100%;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  @media only screen and (max-width: $break-small) {
    flex-direction: column;
  }

  .closeButton {
    margin-left: 3.8rem;
    margin-right: 6rem;
    margin-top: 3.8rem;
    margin-bottom: 9.1rem;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $break-small) {
      margin-bottom: 2.6rem;
    }
    @media only screen and (max-width: $break-small) {
      position: fixed;
      left: 3rem;
      top: 3rem;
    }
  }

  .backButton {
    margin-top: 15.5rem;
    margin-left: 3rem;
    position: absolute;
    z-index: 1;
    @media only screen and (max-width: $break-small) {
      margin-top: 4.5rem;
    }
    &:hover {
      .toolTip {
        visibility: visible;
      }
    }
  }
  .connectionCircleContainer {
    margin-left: -1.2rem;
    display: flex;
    align-items: center;
    .connectionCircle {
      height: 9.6rem;
      width: 9.6rem;
      background-color: rgba(255, 255, 255, 0.5);
      border: solid 1px white;
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        .eventToolTip {
          visibility: visible;
        }
      }

      .connectionImage {
        max-width: 5rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .connectionIconImage {
          max-height: 4.2rem;
        }
      }
    }
    .connectionCircleUpload {
      background-color: rgba(255, 255, 255, 0.5);
      border: solid 1px white;
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .connectionLine {
      width: 9rem;
      height: 1px;
      margin-left: auto;
      opacity: 0.8;
      border-style: solid;
      border-width: 12px;
      border-image-source: linear-gradient(
        to left,
        white,
        rgba(247, 248, 251, 0)
      );
      border-image-slice: 1;
    }
    @media only screen and (max-width: $break-small) {
      display: none;
    }
  }

  .configContainer {
    box-shadow: 0 8px 20px 0 rgba(70, 71, 72, 0.2);
    min-width: 50rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    .topHeaderContainer {
      display: flex;
      padding: 1rem 10.8rem 0rem 10.8rem;
      margin-top: 8.8rem;
      align-items: center;
      transition: all 700ms ease;
      background-color: white;
      .signalNameContainer {
        display: flex;
        align-items: baseline;
        flex-basis: 70%;
        margin-left: 2.4rem;
        .signalNameWrapper {
          display: flex;
          flex-direction: column;
        }
        .errorText {
          color: #e72c31;
          font-size: 1rem;
          font-weight: 500;
        }
        .signalName {
          @include header-secondary;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 40rem;
          border: 1px solid #a2b5c7;
          border-radius: 3px;
          padding: 1.2rem;
          input {
            padding: 0rem;
          }
        }
      }

      .editButton {
        padding: 0.5rem;
      }
      @media only screen and (max-width: $break-small) {
        .signalSaveButton {
          margin-left: auto;
        }
      }
    }
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .tooltext {
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.signalIcon {
  width: 140px;
  height: 140px;
  margin: auto;
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  img {
    max-height: 10rem;
  }
  svg {
    margin-top: -5rem;
  }
}
