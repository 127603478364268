@font-face {
  font-family: 'metaimpact';
  src: url('./font/metaimpact.eot?77322614');
  src:
    url('./font/metaimpact.eot?77322614#iefix') format('embedded-opentype'),
    url('./font/metaimpact.woff2?77322614') format('woff2'),
    url('./font/metaimpact.woff?77322614') format('woff'),
    url('./font/metaimpact.ttf?77322614') format('truetype'),
    url('./font/metaimpact.svg?77322614#metaimpact') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'metaimpact';
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-organization:before {
  content: '\e800';
} /* '' */
.icon-capabilities:before {
  content: '\e801';
} /* '' */
.icon-home:before {
  content: '\e802';
} /* '' */
.icon-drawer:before {
  content: '\e803';
} /* '' */
.icon-connection:before {
  content: '\e804';
} /* '' */
.icon-message:before {
  content: '\e805';
} /* '' */
.icon-filenormal:before {
  content: '\e806';
} /* '' */
.icon-contributions:before {
  content: '\e807';
} /* '' */
.icon-explore:before {
  content: '\e808';
} /* '' */
.icon-mutualspaces:before {
  content: '\e809';
} /* '' */
.icon-not-in-space:before {
  content: '\e80a';
} /* '' */
