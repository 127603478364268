.searchBarContainer {
  height: 3.6rem;
  position: fixed;
  right: 2.4rem;
  top: 2.4rem;
  z-index: 1000;
  align-content: right;
  max-width: 300px;
  width: 300px;
  transition: all 300ms;
  background: #ffffff;
  border-radius: 18px;
  cursor: pointer;
  &.open {
    max-width: 600px;
    width: 600px;
  }
}

.searchBarControlsContainer {
  box-shadow: 0 15px 35px rgba(70, 71, 72, 0.15);
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0;
  height: 3.6rem;
  width: 100%;
  border-radius: 18px;

  .defaultStateMsg {
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
    top: 8px;
    left: 36px;
    color: #6e7c8a;
  }

  .searchIcon {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 6px;
    top: 6px;
    cursor: pointer;
  }

  .filterIcon {
    height: 32px;
    width: 32px;
    transform: scale(0.6);
    position: absolute;
    right: 45px;
    top: 4px;
    background-color: #1091a1;
    mask-image: url('../../../images/filter.svg');
  }

  .sortIcon {
    height: 32px;
    width: 32px;
    transform: scale(0.6);
    position: absolute;
    right: 77px;
    top: 4px;
    background-color: #1091a1;
    mask-image: url('../../../images/sort.svg');
  }
}

.searchStringInputContainer {
  position: relative;
  height: 4rem;
  z-index: 130000;
  border: 2px solid #1091a1;
  border-radius: 30px;
  background-color: #ffffff;

  .searchStringInput {
    position: absolute;
    left: 40px;
    top: 4px;
    height: 3rem;
    z-index: 130000;
    width: 40rem;
    font-size: 14px;
  }

  .searchIcon {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 10px;
    top: 8px;
  }

  .closeIcon {
    position: absolute;
    right: 8px;
    top: 6px;
    z-index: 13001;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}
