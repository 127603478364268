@import '../../Styles/ColorVariables.scss';
@import '../../Styles/Typography';

.container {
  width: 100%;
  .instructionText {
    @include text-info;
  }
}
.keyBtnContainer {
  display: flex;
  width: 100%;
  button:nth-child(2) {
    margin-left: 1.6rem;
  }
  button:nth-child(3) {
    margin-left: auto;
  }
}

.resetIcon {
  margin-right: 0.9rem;
  height: 2.3rem;
}
