.ql-hash-tag-loading {
  line-height: 2rem;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-hash-tag-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-hash-tag-list > li::before {
  display: none;
}

.ql-hash-tag-list-item {
  cursor: pointer;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
  margin-top: 0 !important;
  text-align: left;
  line-height: 2rem;
}

.ql-hash-tag-list-item.disabled {
  cursor: auto;
}

.ql-hash-tag-list-item.selected {
  /* background-color: #d3e1eb; */
  text-decoration: none;
  background-color: #edf1f7 !important;
  border-radius: 4px;
}

.hashTag {
  height: 24px;
  width: 65px;
  border-radius: 4px;
  padding: 3px 0;
  margin-right: 0;
  user-select: all;
  cursor: pointer;
}
.hash-tag > span {
  margin: 0 3px;
}

.orgImageIcon {
  border-color: #3d444a !important;
  background-color: white;
}

[data-hash-tag] {
  background: transparent;
  color: #041e2b !important;
  padding: 3px;
  padding-right: 0;
  border-radius: 4px;
  margin-left: 0;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.8rem;
}
.ql-hash-tag-list-item[data-hash-tag] {
  background: inherit;
}
