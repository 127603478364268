@import '../../Styles/ColorVariables';
@import '../../Styles/Typography';
$break-small: 412px;
$break-large: 768px;

.stepInstructions {
  @include body-primary;
  margin-bottom: 3rem;
  padding: 0rem 10.8rem;
  font-size: 12px;
  color: #616a73;
}
.breakLine {
  height: 2px;
  width: -webkit-fill-available;
  border: 0;
  background: #f7f8fb;
  border-radius: 3px;
  margin: 0rem 10.8rem 1.2rem 10.8rem;
  padding: 2px;
}
.reactionContainer {
  display: flex;
  align-items: center;
  margin: 0rem 10.8rem 1.2rem 10.8rem;
  padding: 1rem;
  &[data-error='true'] {
    border-width: 6px;
    border-style: solid;
    border-color: rgba(231, 44, 49, 0.2);
    background-color: rgba(231, 44, 49, 0.1);
    &:hover {
      .reactionInformation {
        .reactionTitle {
          color: #3d444a;
        }
        .reactionDescription {
          color: #3d444a;
        }
      }
      .deleteReaction {
        visibility: visible;
        color: #3d444a;
        margin-left: initial;
      }
      .errorReaction {
        visibility: visible;
      }
    }
  }
  &:hover {
    .reactionInformation {
      .reactionTitle {
        color: #1091a1;
      }
      .reactionDescription {
        color: #1091a1;
      }
    }
    .deleteReaction {
      visibility: visible;
    }
  }
  .deleteReaction {
    margin-left: auto;
    text-transform: capitalize;
    font-weight: normal;
    color: #1091a1;
    visibility: hidden;
    &:hover {
      background-color: transparent;
    }
  }
  .errorReaction {
    margin-left: auto;
    text-transform: capitalize;
    font-weight: 600;
    color: #3d444a;
    visibility: hidden;
  }
  .reactionInformation {
    display: flex;
    flex-direction: column;
    margin-left: 2.45rem;
    .reactionTitle {
      @include title-primary;
      padding: 0;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      color: #097886;

      &:hover {
        background-color: transparent;
      }
      &:disabled {
        background-color: transparent;
      }
    }
    .reactionDescription {
      @include body-secondary;
      text-align: left;

      .keyLabelText {
        position: relative;
        top: -8px;
        left: 5px;
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  }
}
.addReactionBtn {
  padding-bottom: 20rem;
}
.addReactionContainer {
  border: solid 2px #edf1f7;
  display: flex;
  flex-direction: column;
  padding-top: 1.2rem;
  margin: 0rem 5.4rem 15rem 5.4rem;

  .breakLine {
    height: 2px;
    width: -webkit-fill-available;
    border: 0;
    background: #f7f8fb;
    border-radius: 3px;
    margin: 0rem 0rem 1.2rem 0rem;
  }
  .addReaction {
    display: flex;
    align-items: center;
    padding: 0rem 5.4rem 1.2rem 5.4rem;
    position: relative;

    &.withNoCompanyMetaKeyNotice {
      margin-bottom: 16px;
    }

    .reactionInformation {
      display: flex;
      flex-direction: column;
      margin-left: 2.45rem;
      .reactionTitle {
        @include title-primary;
        padding: 0;
        color: #097886;
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        &:hover {
          background-color: transparent;
        }
      }
      .reactionChoiceTitle {
        @include title-primary;
        font-weight: 600;
        padding: 0;
        color: #1091a1;
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        &:hover {
          background-color: transparent;
        }
        &.disabled {
          color: #979fa7;
        }
      }

      .noCompanyIdNotice {
        position: absolute;
        top: 32px;

        span.text {
          position: relative;
          top: -6px;
          left: 6px;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #616a73;
        }
      }
    }
  }
  @media only screen and (max-width: $break-small) {
    padding: 1.2rem 1.7rem;
    margin-left: -1.9rem;
    margin-right: -1.9rem;
  }
}

.reactionChoiceContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15rem;
  background-color: #f7f8fb;
  .header {
    display: flex;
    padding: 1.6rem 10.8rem;
    align-items: center;
    background-color: white;
    border-top: 2px solid #f7f8fb;

    .assignCompaniesHeader {
      .text {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #3d444a;
        position: relative;
        left: 12px;
        top: -8px;
      }
    }
  }
  .reactionTitle {
    color: #097886;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 600;
    padding-left: 2.6rem;
  }
  .buttonWrapper {
    margin-left: auto;
  }
  .bottomContainer {
    background-color: #f7f8fb;
    padding: 2.4rem 10.8rem 15rem 10.8rem;
    .bottomField {
      border-radius: 5px;
      background-color: #ffffff;
      height: 9.6rem;
      border: 2px solid #edf1f7;
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      text-transform: unset;
      font-weight: unset;
      margin-bottom: 1.2rem;
      &:hover {
        border-color: #1091a1;
        .buttonContent {
          .title {
            color: #1091a1;
          }
        }
      }
      .buttonContent {
        display: flex;
        flex-direction: column;
        text-align: left;
        .title {
          color: #3d444a;
          font-size: 12px;
          font-weight: 600;
          line-height: 1.33;
          margin-bottom: 0.9rem;
        }
        .fieldCount {
          font-size: 12px;
          line-height: 1.5;
          color: #616a73;
        }
      }
    }
    .businessEntityForm {
      border-radius: 5px;
      background-color: #ffffff;
      border: 2px solid #edf1f7;
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      overflow: auto;
      .businessEntityHeader {
        display: flex;
        flex-direction: column;
        .signalNameWrapper {
          display: flex;
          flex-direction: column;
          border: 1px solid #a2b5c7;
          border-radius: 3px;
          padding: 1.2rem;
          input {
            padding: 0rem;
          }
        }
        .errorText {
          color: #e72c31;
          font-size: 1rem;
          font-weight: 500;
        }
        .businessEntityName {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.33;
          color: #3d444a;
          margin-bottom: 2rem;
          display: flex;
          align-items: baseline;
          input {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.33;
            color: #3d444a;
          }
        }
        .businessEntityInfo {
          font-size: 12px;
          line-height: 1.5;
          color: #616a73;
          margin-bottom: 4.5rem;
        }
      }
      .businessEntityFormContainer {
        margin-top: 2.4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        overflow: hidden;
        .formRightBorder {
          border-right: 2px solid #edf1f7;
          border-radius: 1px;
          width: 0.2rem;
          flex-basis: 33%;
        }
        .businessEntityFormRow {
          flex-basis: 66%;
        }
        .formRow {
          display: flex;
          flex-direction: column;
          overflow: auto;
          width: 100%;
          .formTitle {
            font-size: 10px;
            font-weight: bold;
            margin-bottom: 2.4rem;
            text-transform: uppercase;
            line-height: 1.4;
            letter-spacing: 0.8px;
            color: #979fa7;
          }
          .fieldFormTitle {
            margin-left: 30.2rem;
          }
          .fieldSelection {
            margin-right: 3rem;
            min-width: 272px;
            position: relative;
            .fieldContainer {
              height: 38px;
              padding: 1rem;
              min-width: 272px;
              border-radius: 3px;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
              background-color: #ffffff;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: grab;
              .fieldType {
                font-size: 10px;
                font-weight: bold;
                line-height: 1.4;
                letter-spacing: 0.8px;
                color: #979fa7;
                text-transform: uppercase;
                width: 2rem;
              }
              .fieldName {
                font-size: 12px;
                line-height: 1.5;
                color: #616a73;
                width: 133px;
              }
            }
            .fieldContainerMargin {
              margin-bottom: 2.4rem;
              position: relative;
            }
            .fieldContainerMarginSmall {
              margin-bottom: 1.6rem;
              position: relative;
            }
            .fieldContainerDottedLine {
              position: absolute;
              top: 40px;
              height: 21px;
              margin-left: 1.2rem;
              width: 30px;
              background: linear-gradient(#a2b5c7 33%, #fff 0%) left/2px 6px
                repeat-y;
            }
            .requiredText {
              margin-left: 1.2rem;
              font-size: 1rem;
              font-weight: 500;
              line-height: 1.4;
              color: #979fa7;
              position: absolute;
              top: 4.2rem;
            }
            .formDropContainer {
              display: flex;
              margin-right: -3rem;
              .newFieldContainer {
                width: 272px;
                height: 38px;
                border-radius: 3px;
                background-color: #edf1f7;
                margin-left: 1.2rem;
              }
              .formDrop {
                height: 38px;
                border: 2px dashed #6aced3;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 272px;
                .formDropText {
                  font-size: 12px;
                  line-height: 1.5;
                  color: #1091a1;
                }
              }
              .arrow {
                display: flex;
                align-items: center;
                .line {
                  width: 16px;
                  background: #6aced3;
                  height: 4px;
                }
                .point {
                  border-top: 6px solid transparent;
                  border-bottom: 6px solid transparent;
                  border-left: 9px solid #6aced3;
                }
              }
              .newArrow {
                display: flex;
                align-items: center;
                .line {
                  width: 16px;
                  background: #a2b5c7;
                  height: 4px;
                }
                .point {
                  border-top: 6px solid transparent;
                  border-bottom: 6px solid transparent;
                  border-left: 9px solid #a2b5c7;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mappingRows {
  display: flex;
  flex-direction: column;
}

.dropTargetField {
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  min-height: 3.8rem;
}

.businessEntityList {
  width: 272px;
  height: 38px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 2px solid #edf1f7;
  display: flex;
  align-items: center;
  padding: 1rem;
  .fieldType {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #979fa7;
    width: 6rem;
    display: flex;
  }
  .fieldName {
    font-size: 12px;
    line-height: 1.5;
    color: #616a73;
    padding-left: 2rem;
  }
  .cube {
    margin-left: auto;
  }
}

[data-hover='true'] {
  background-color: rgba(106, 206, 211, 0.1);
}

[data-hovererror='true'] {
  background-color: rgba(231, 44, 49, 0.1);
}
.droppedFieldContainer {
  display: flex;
  margin-right: -3rem;
  .arrow {
    display: flex;
    align-items: center;
    .line {
      width: 16px;
      background: #a2b5c7;
      height: 4px;
    }
    .point {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 9px solid #a2b5c7;
    }
  }
}

.droppedNewFieldContainer {
  height: 38px;
  padding: 1rem;
  min-width: 272px;
  border-radius: 3px;
  border: 2px solid #edf1f7;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.2rem;
  .line {
    background: #a2b5c7;
  }
  .point {
    border-left: 9px solid #a2b5c7;
  }
  .fieldType {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.8px;
    color: #979fa7;
    text-transform: uppercase;
  }
  .fieldName {
    font-size: 12px;
    line-height: 1.5;
    color: #616a73;
    width: 133px;
  }
}
