.potentialInviteeItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.7rem 1.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
  width: fit-content;
  height: 3rem;
}
.potentialInviteeItem .tooltip {
  visibility: hidden;
  z-index: 9999;
  height: 36px;
  width: max-content;
  position: relative;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  color: #fff;
  padding: 0.9rem 1.6rem;
  text-align: center;
  background-color: #3c4349;
  position: absolute;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem !important;
  right: 1px;
}
.potentialInviteeItem .hideTooltip {
  visibility: hidden;
  z-index: 9999;
  height: 36px;
  width: max-content;
  position: relative;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  color: #fff;
  padding: 0.9rem 1.6rem;
  text-align: center;
  background-color: #3c4349;
  position: absolute;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem !important;
  right: 1px;
}
.potentialInviteeItem:hover .tooltip {
  visibility: visible;
}
.potentialInviteeItem .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3c4349 transparent transparent transparent;
}
.potentialInviteeItem:hover {
  background-color: #edf1f7;
}
.potentialInviteeInfo {
  display: flex;
  width: 220px;
  align-items: center;
  overflow-x: hidden;
  padding-top: 1rem;
}
.inviteeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding-top: 1rem;
  position: relative;
}
.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0.2rem;
  margin-right: 1.6rem;
}
.userImage {
  height: 1.2rem;
  width: 0.3rem;
  border-radius: 50%;
  overflow: hidden;
}

.orgContainer {
  position: absolute;
  bottom: -2px;
  right: 1rem;
  padding: 0.05rem;
  object-fit: contain;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #d6dfe8;
  background-color: #fff;
}

.orgContainer img {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  object-fit: cover;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
  /* padding: 10px 20px; */
  margin-top: 0 !important;
  text-align: left;
  line-height: 2rem !important;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  /* background-color: #d3e1eb; */
  text-decoration: none;
  background-color: #edf1f7 !important;
  border-radius: 4px;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 4px;
  background-color: #cfe9ec;
  padding: 3px 0;
  margin-right: 0;
  user-select: all;
  cursor: pointer;
}
.mention > span {
  margin: 0;
}
.ql-mention-list-container {
  width: 320px !important;
  max-height: 320px;
  padding: 6px;
  box-shadow: 0px 8px 20px rgba(70, 71, 72, 0.2);
  border-radius: 4px;
  overflow-y: auto;
}

.potentialInviteeItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.7rem 1.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
  width: fit-content;
  height: 3rem;
}
.potentialInviteeItem .tooltip {
  visibility: hidden;
  z-index: 9999;
  height: 36px;
  width: max-content;
  position: relative;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  color: #fff;
  padding: 0.9rem 1.6rem;
  text-align: center;
  background-color: #3c4349;
  position: absolute;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem !important;
  right: 1px;
}
.potentialInviteeItem .hideTooltip {
  visibility: hidden;
  z-index: 9999;
  height: 36px;
  width: max-content;
  position: relative;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  color: #fff;
  padding: 0.9rem 1.6rem;
  text-align: center;
  background-color: #3c4349;
  position: absolute;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem !important;
  right: 1px;
}
.potentialInviteeItem:hover .tooltip {
  visibility: visible;
}
.potentialInviteeItem .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3c4349 transparent transparent transparent;
}
.potentialInviteeItem:hover {
  background-color: #edf1f7;
}
.potentialInviteeInfo {
  display: flex;
  width: 220px;
  align-items: center;
  overflow-x: hidden;
  padding-top: 1rem;
}
.inviteeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding-top: 1rem;
  position: relative;
}
.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0.2rem;
  margin-right: 1.6rem;
}
.userImage {
  height: 1.2rem;
  width: 0.3rem;
  border-radius: 50%;
  overflow: hidden;
}

.orgContainer {
  position: absolute;
  bottom: -2px;
  right: 1rem;
  padding: 0.05rem;
  object-fit: contain;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #d6dfe8;
  background-color: #fff;
}

.orgContainer img {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  object-fit: cover;
  align-items: center;
  display: flex;
  justify-content: center;
}

[data-mention-user] {
  background: #cfe9ec;
  color: #097886;
  padding: 3px;
  margin-left: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  > span {
    line-height: 2rem;
  }
}
.ql-mention-list-item[data-mention-user] {
  background: inherit;
}
