@import './ColorVariables';

/* Text styles */
@mixin header-primary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 4rem;
  color: $text-dark;
}
@mixin header-secondary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.8rem;
  color: $text-dark;
}
@mixin header-condensed {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.4rem;
  color: $text-dark;
}
@mixin title-primary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: $text-dark;
}
@mixin body-primary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: $text-dark;
}
@mixin label-primary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: $text-light;
}
@mixin label-secondary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 1px;
  color: $accent;
}
@mixin title-secondary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: $text-dark;
}
@mixin body-secondary {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  color: $text-light;
}
@mixin text-link {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  color: $accent;
}
@mixin text-info {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2;
  color: $text-info;
}

@mixin body-empty {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  font-style: italic;
  color: $primary-dark;
}

@mixin body-semiBold {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: $primary;
  text-transform: uppercase;
}

@mixin body-1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: $text-dark;
}
@mixin body-2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33;
  color: $text-light;
}

@mixin boldBlueCaps {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 1px;
  color: #097886;
}
